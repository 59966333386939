<template>
  <v-menu
    :disabled="!!readonly"
    persistent
    :close-on-back="false"
    :close-on-content-click="false"
    v-model="display"
    :fullscreen="xs"
    :width="xs ? undefined : 'auto'"
  >
    <template v-slot:activator="{ props }">
      <v-text-field
        ref="textfield"
        v-bind="{ ...textFieldProps, ...props }"
        :disabled="disabled"
        :loading="loading"
        :label="label"
        :model-value="modelValue ? dayjs(modelValue).format(type == 'datetime' ? 'lll' : 'l') : null"
        readonly
        prepend-inner-icon="mdi-calendar"
        @click:clear="modelValue = null"
      >
        <template #append v-if="$slots.append">
          <slot name="append"></slot>
        </template>
        <template #label>
          <slot name="label">{{ label }}</slot>
        </template>
        <template #details>
          <slot name="details">{{ details }}</slot>
        </template>
      </v-text-field>
    </template>

    <v-card min-width="300">
      <v-tabs bg-color="primary" v-if="type == 'datetime'" fixed-tabs v-model="activeTab">
        <v-tab key="calendar">
          <slot name="dateIcon">
            <v-icon>mdi-calendar</v-icon>
          </slot>
        </v-tab>
        <v-tab key="timer" :disabled="dateNotSelected">
          <slot name="timeIcon">
            <v-icon>mdi-clock</v-icon>
          </slot>
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-container :class="[{ 'bg-primary': type === 'date' }, 'font-weight-bold text-center']">{{
        formattedDatetime
      }}</v-container>
      <v-divider></v-divider>
      <v-window v-model="activeTab">
        <v-window-item key="calendar">
          <v-date-picker
            color="primary"
            :model-value="date.toDate()"
            @update:model-value="date = dayjs($event)"
            v-model:input-mode="input_mode"
            elevation="0"
            class="w-100"
            hide-actions
            v-bind="$attrs"
            hide-header
          ></v-date-picker>
        </v-window-item>
        <v-window-item class="my-1" key="timer" v-if="type == 'datetime'">
          <v-expand-transition>
            <v-container v-if="digital">
              <v-select
                :label="$t('dialog.hour')"
                :model-value="time.hour()"
                @update:model-value="time = time.hour($event)"
                auto-select-first
                :items="hours"
              />
              <v-select
                hide-details
                :label="$t('dialog.minutes')"
                :model-value="time.minute()"
                @update:model-value="time = time.minute($event)"
                auto-select-first
                :items="minutes"
              />
            </v-container>
          </v-expand-transition>
        </v-window-item>
      </v-window>
      <v-spacer></v-spacer>
      <v-divider />
      <v-card-actions>
        <v-btn-toggle class="d-none" density="compact" v-if="activeTab == 1" v-model="digital">
          <v-btn size="small" icon><v-icon>mdi-clock</v-icon></v-btn>
          <v-btn size="small" icon><v-icon>mdi-clock-digital</v-icon></v-btn>
        </v-btn-toggle>
        <v-spacer></v-spacer>
        <slot name="actions">
          <v-btn color="secondary" variant="text" @click="clearHandler">{{ $t("dialog.cancel") }}</v-btn>
          <v-btn color="primary" variant="text" @click="okHandler">{{
            activeTab == 0 && type == "datetime" ? $t("dialog.select_time") : $t("dialog.ok")
          }}</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script lang="ts" setup>
import _range from "lodash/range";
import { ref, onMounted, computed, watch, PropType } from "vue";
import { useDayJS } from "@/bytenest-cli/composable/useDayJS";
import { useDisplay } from "vuetify";
const { dayjs } = useDayJS();

const props = defineProps({
  disabled: {
    type: Boolean,
  },
  loading: {
    type: Boolean,
  },
  label: {
    type: String,
    default: "",
  },
  details: {
    type: String,
    default: "",
  },
  type: { type: String as PropType<"date" | "datetime">, required: true },
  textFieldProps: {
    type: Object,
  },
  readonly: {
    type: Boolean,
  },
});

const modelValue = defineModel<string|null>();

const emit = defineEmits<{
  selected: [string];
  blur: [];
}>();

const date = ref(dayjs());
const time = ref(dayjs().hour(9).minute(0).second(0));

const { xs } = useDisplay();

const datetime = computed(() => {
  const combinedDateTime = date.value.set("hour", time.value.hour()).set("minute", time.value.minute());
  if (props.type == "datetime") return combinedDateTime.format("YYYY-MM-DD HH:mm:ss");
  else return combinedDateTime.format("YYYY-MM-DD");
});

onMounted(init);
watch(modelValue, init);

function init() {
  date.value = modelValue.value ? dayjs(modelValue.value) : dayjs();
  time.value = modelValue.value ? dayjs(modelValue.value) : dayjs().hour(9).minute(0).second(0);
}

const activeTab = ref(0);
function okHandler() {
  if (activeTab.value == 0 && props.type == "datetime") {
    activeTab.value = 1;
    return;
  }
  display.value = false;
  modelValue.value = datetime.value;
  emit("selected", datetime.value);
}

const formattedDatetime = computed(() => {
  if (!datetime.value) return "";
  if (props.type == "date") return dayjs(datetime.value).format("LL");
  else return dayjs(datetime.value).format("LLL");
});
const dateNotSelected = computed(() => !date.value);

const input_mode = ref<"calendar" | "keyboard">("calendar");

const display = ref(false);

watch(display, (value) => {
  if (!value) {
    emit("blur");
  } else activeTab.value = 0;
});

function clearHandler() {
  display.value = false;
  init();
}
</script>

<script lang="ts">
export default {
  data() {
    return {
      digital: 1,
      hours: _range(7, 24).map((value) => ({ title: value.toString().padStart(2, "0"), value })),
      minutes: _range(0, 60, 5).map((value) => ({
        title: value.toString().padStart(2, "0"),
        value,
      })),
      selectedHour: null,
      selectedMinutes: null,
    };
  },
};
</script>
